<template>
  <div class="mailing-label" :style="computedStyle(settings.label.style)">
    <p class="mailing-label-principal" :style="computedStyle(settings.label.style)">
      Principal
    </p>
    <p class="mailing-label-name" :style="computedStyle(settings.name.style)">
      <template v-if="settings.name.textFormat === 'popular'">
        {{ school.name.popular }}
      </template>
      <template v-else>
        {{ school.name.legal }}
      </template>
    </p>
    <p class="mailing-label-address" :style="computedStyle(settings.address.style)">{{ school.address.line1 }}</p>
    <p class="mailing-label-address" :style="computedStyle(settings.address.style)">{{ school.address.line2 }}</p>
    <p class="mailing-label-address" :style="computedStyle(settings.address.style)">{{ school.address.city }},
      {{ school.address.state }} {{ school.address.zip }}</p>
  </div>
</template>

<script>
import styleMixin from '@/mixins/style.mixin';

export default {
  name: 'SchoolLabel',
  mixins: [ styleMixin ],
  props: {
    settings: {
      type: Object,
      required: true
    },
    school: {
      type: Object,
      default: () => ({
        name: {
          first: '',
          last: '',
        },
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
      }),
    },
  },
};
</script>

<style scoped>
  .mailing-label {
    width: 100%;
    height: 100%;
    padding: 0.125in;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    page-break-inside: avoid;
    font-size: 12px;
    border: dashed 1px #ccc;
  }

  .mailing-label-principal,
  .mailing-label-name,
  .mailing-label-address {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mailing-label-address {
    font-size: 9pt;
  }

  .sorting-btn-border {
    border: 1px solid #d8d6de;
  }
</style>
