<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Printable - ${year}`" />
      <b-breadcrumb-item text="School Labels" active />
    </template>

    <template #actions="{ state }">
      <b-button v-if="state.editing" variant="outline-primary" size="sm" class="d-inline-flex align-items-center mr-50" @click="state.editing = false">
        <font-awesome-icon icon="fa-solid fa-pen-to-square" class="mr-50" /> Editing
      </b-button>
    </template>

    <template #dropdown-options="{ state }">
      <b-dropdown-item @click="state.editing = !state.editing">
        <font-awesome-icon icon="fa-solid fa-pen-to-square" />
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <b-row>
        <b-col cols="3" class="d-print-none">
          <template v-if="state.editing">
            <!-- Settings -->
            <settings-container title="Label Settings"
                                :settings="settings"
                                :settings-key="key"
                                :has-changed="hasChanges"
                                @revert="settings = JSON.parse(JSON.stringify(initialSettings))"
                                @updated="syncInitialSettings">
              <!-- Container -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-container header-class="options-header" title="Container"></options-header>
                <b-collapse id="options-container" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-container-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-container-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.container.style.paddingTop" label="Top" input-id="container-padding-top" placeholder="0.5in"/>
                          <option-value-unit v-model="settings.container.style.paddingBottom" label="Bottom" input-id="container-padding-bottom" placeholder="0.5in"/>
                          <option-value-unit v-model="settings.container.style.paddingLeft" label="Left" input-id="container-padding-left" placeholder="0.19in"/>
                          <option-value-unit v-model="settings.container.style.paddingRight" label="Right" input-id="container-padding-right" placeholder="0.19in"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Grid Options -->
                    <options-header v-b-toggle.options-container-grid header-class="options-header" title="Grid"></options-header>
                    <b-collapse id="options-container-grid" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.container.style.gridColumnGap" label="Column Gap" input-id="container-column-gap" placeholder="0.125in"/>
                          <option-value-unit v-model="settings.container.style.gridRowGap" label="Row Gap" input-id="container-column-gap" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- Label -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-label header-class="options-header" title="Label"></options-header>
                <b-collapse id="options-label" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">
                    <!-- Font Options -->
                    <options-header v-b-toggle.options-label-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-label-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.label.style.color" label="Font Color" input-id="general-color"/>
                        <option-value-unit v-model="settings.label.style.fontSize" label="Font Size" input-id="label-border-size" placeholder="12px"/>
                        <option-select v-model="settings.label.style.fontFamily" label="Font Family" input-id="label-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
                        <option-select v-model="settings.label.style.fontWeight" label="Font Weight" input-id="label-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Border Options -->
                    <options-header v-b-toggle.options-label-border header-class="options-header" title="Border"></options-header>
                    <b-collapse id="options-label-border" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.label.style.borderColor" label="Border Color" input-id="label-border-color"/>
                        <option-value-unit v-model="settings.label.style.borderWidth" label="Border Width" input-id="label-border-width" placeholder="1px"/>
                        <option-select v-model="settings.label.style.borderStyle" :options="options.borderStyle" label="Border Style" input-id="label-border-style" placeholder="dashed"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-label-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-label-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.label.style.paddingTop" label="Top" input-id="label-padding-top" placeholder="0.125in"/>
                          <option-value-unit v-model="settings.label.style.paddingBottom" label="Bottom" input-id="label-padding-bottom" placeholder="0.125in"/>
                          <option-value-unit v-model="settings.label.style.paddingLeft" label="Left" input-id="label-padding-left" placeholder="0.125in"/>
                          <option-value-unit v-model="settings.label.style.paddingRight" label="Right" input-id="label-padding-right" placeholder="0.125in"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- Name -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-name header-class="options-header" title="Name"></options-header>
                <b-collapse id="options-name" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Text Options -->
                    <options-header v-b-toggle.options-name-text header-class="options-header" title="Text"></options-header>
                    <b-collapse id="options-name-text" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-select v-model="settings.name.textFormat"
                                       :options="[
                                        { value: 'popular', label: 'Popular Name' },
                                        { value: 'legal', label: 'Legal Name' },
                                      ]"
                                       label="Text Format" input-id="student-name-format" placeholder="Popular Name"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-name-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-name-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-value-unit v-model="settings.name.style.fontSize" label="Font Size" input-id="name-font-size" placeholder="12px"/>
                        <option-select v-model="settings.name.style.fontWeight" label="Font Weight" input-id="name-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-name-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-name-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.name.style.paddingTop" label="Top" input-id="name-padding-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.paddingBottom" label="Bottom" input-id="name-padding-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.paddingLeft" label="Left" input-id="name-padding-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.paddingRight" label="Right" input-id="name-padding-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-name-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-name-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.name.style.marginTop" label="Top" input-id="name-margin-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.marginBottom" label="Bottom" input-id="name-margin-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.marginLeft" label="Left" input-id="name-margin-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.name.style.marginRight" label="Right" input-id="name-margin-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- Address -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-address header-class="options-header" title="Address"></options-header>
                <b-collapse id="options-address" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-address-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-address-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-value-unit v-model="settings.address.style.fontSize" label="Font Size" input-id="school-font-size" placeholder="9pt"/>
                        <option-select v-model="settings.address.style.fontWeight" label="Font Weight" input-id="school-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-address-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-address-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.address.style.paddingTop" label="Top" input-id="address-padding-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.paddingBottom" label="Bottom" input-id="address-padding-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.paddingLeft" label="Left" input-id="address-padding-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.paddingRight" label="Right" input-id="address-padding-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-address-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-address-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.address.style.marginTop" label="Top" input-id="address-margin-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.marginBottom" label="Bottom" input-id="address-margin-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.marginLeft" label="Left" input-id="address-margin-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.address.style.marginRight" label="Right" input-id="address-margin-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                  </b-card-body>
                </b-collapse>
              </b-card>
            </settings-container>
          </template>
          <template v-else>
            <!-- Sorting -->
            <b-card-actions title="Sorting" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Sort By" label-for="sorting-by">
                <b-input-group class="flex-nowrap">
                  <template v-if="sorting.drag">
                    <draggable v-model="sorting.by" class="p-50 flex-grow-1 rounded-left sorting-btn-border">
                      <b-badge v-for="(item, index) in sorting.by" :key="index" variant="light-primary" class="mr-50 font-small-3 font-weight-normal cursor-move">
                        {{ sorting.options.find(option => option.value === item).label }}
                        <font-awesome-icon :icon="['fas', 'grip-vertical']" class="my-auto ml-25"/>
                      </b-badge>
                    </draggable>
                  </template>
                  <template v-else>
                    <v-select v-model="sorting.by" multiple
                              :options="sorting.options" label="label"
                              :reduce="option => option.value"
                              input-id="sorting-by" class="h-100"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"/>
                  </template>

                  <b-input-group-append>
                    <b-button v-if="sorting.by.length > 1" size="sm" variant="white" class="sorting-btn-border border-left-0" @click="sorting.drag = !sorting.drag" title="Selected Order">
                      <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                    </b-button>
                    <b-button size="sm" variant="white" class="sorting-btn-border" title="Sort Order" @click="sorting.desc = !sorting.desc">
                      <font-awesome-icon :icon="['fas', sorting.desc ? 'sort-alpha-down-alt' : 'sort-alpha-down']" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-card-actions>
            <!-- Paging -->
            <b-card-actions title="Paging" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Page Size" label-for="paging-size">
                <v-select id="paging-size"
                          v-model="paging.size"
                          :options="paging.sizes"
                          :reduce="option => option.value"
                          :clearable="false"
                          append-to-body/>
              </b-form-group>

              <b-pagination v-if="paging.size !== -1"
                            id="paging-page"
                            v-model="paging.page"
                            :total-rows="paging.total"
                            :per-page="paging.size"
                            first-number
                            last-number
                            :limit="3"
                            pills align="fill"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="14"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="14"/>
                </template>
              </b-pagination>
            </b-card-actions>
            <!-- Print -->
            <b-button block variant="primary" @click="printWindow()">
              <font-awesome-icon icon="fa-solid fa-print" />
              <span class="align-middle ml-50">Print</span>
            </b-button>
          </template>
        </b-col>
        <b-col cols="9">
					<!-- Alerts -->
          <b-alert :show="hasChanges" variant="danger" class="d-print-none">
            <div class="d-flex">
              <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mt-25 mr-25"/>
              <div class="align-start ml-50 font-small-3">
                <strong class="d-block">Unsaved Changed!</strong>
                <span>Don't forget to save your changes before leaving the page.</span>
              </div>
            </div>
          </b-alert>
          <b-alert :show="isContentScaled" variant="primary" dismissible class="d-print-none">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
            <span class="align-middle ml-50 font-small-3">
              The content has been scaled down to fit the page.
            </span>
          </b-alert>
					<b-alert :show="state.editing" variant="primary" dismissible class="d-print-none">
						<b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
						<span class="align-middle ml-50 font-small-3">
              While in edit mode, each configurable area will have a dashed border around it to help guide you.
            </span>
					</b-alert>
					<!-- Content -->
          <b-overlay :show="schools.loading" variant="white" opacity="1" class="overlay-wrapper">
            <template #overlay>
              <div class="d-flex align-items-center flex-column">
                <b-spinner small label="Loading..."></b-spinner>
                <small class="mt-50">Loading...</small>
              </div>
            </template>
            <template v-if="state.editing">
              <div v-for="currentPage in 1" :key="currentPage"
                   class="mailing-label-container" :style="computedStyle(settings.container.style)">
                <school-label v-for="(school, index) in getPageItems(computedSchools, currentPage, 30)" :key="index"
                               :settings="settings"
                               :school="school"
                               class="mailing-label-editor"
                />
              </div>
            </template>
            <template v-else>
              <div v-for="currentPage in getPageCount(computedSchools, 30)" :key="currentPage"
                   class="mailing-label-container" :style="computedStyle(settings.container.style)">
                <school-label v-for="(school, index) in getPageItems(computedSchools, currentPage, 30)" :key="index"
                               :settings="settings"
                               :school="school"/>
              </div>
            </template>
          </b-overlay>
        </b-col>
      </b-row>
    </template>

    <template #debug>
      <debug>
        {{ settings }}
      </debug>
      <debug>
        {{ schools }}
      </debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select'
import Fuse from 'fuse.js';
import {API, graphqlOperation} from 'aws-amplify';
import { listSchools, listSelectionsForSchools} from './labels';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import SchoolLabel from './SchoolLabel.vue';
import SettingsContainer from '../settings/SettingsContainer.vue';
import OptionsSyncContainer from '../settings/OptionsSyncContainer.vue';
import OptionsHeader from '../settings/OptionsHeader.vue';
import OptionColor from '../settings/OptionColor.vue';
import OptionImage from '../settings/OptionImage.vue';
import OptionSelect from '../settings/OptionSelect.vue';
import OptionValueUnit from '../settings/OptionValueUnit.vue';

import printableSettingsMixin from '../settings/settings.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import printMixin from '../print.mixin';
import styleMixin from '@/mixins/style.mixin';
import draggable from 'vuedraggable';
import _ from 'lodash';

export default {
  components: {
    draggable,
    SchoolLabel,
    SettingsContainer,
    PageLayout,
    OptionSelect,
    OptionValueUnit,
    OptionColor,
    OptionImage,
    OptionsHeader,
    OptionsSyncContainer,
    BCardActions,
    vSelect
  },
  mixins: [ settingsMixin, styleMixin, printMixin, printableSettingsMixin ],
  data() {
    return {
      key: 'labels-school',
      settings: {
        container: {
          style: {
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
            gridColumnGap: {
              value: null,
              unit: null
            },
            gridRowGap: {
              value: null,
              unit: null
            }
          }
        },
        label: {
          style: {
            width: null,
            height: null,
            color: null,
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            borderColor: null,
            borderStyle: null,
            borderWidth: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          },
        },
        name: {
          format: null,
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          },
        },
        address: {
          textFormat: '',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
      },
      options: {
        borderStyle: [
          { value: 'none', label: 'None'},
          { value: 'solid', label: 'Solid'},
          { value: 'dashed', label: 'Dashed'},
          { value: 'dotted', label: 'Dotted'},
          { value: 'double', label: 'Double'},
          { value: 'groove', label: 'Groove'},
          { value: 'ridge', label: 'Ridge'},
          { value: 'inset', label: 'Inset'},
          { value: 'outset', label: 'Outset'},
          { value: 'hidden', label: 'Hidden'},
        ],
        display: [
          { value: 'flex', label: 'Flex' },
          { value: 'block', label: 'Block' },
          { value: 'inline-block', label: 'Inline Block' },
          { value: 'inline', label: 'Inline' },
          { value: 'none', label: 'None' },
        ],
        fontFamily: [
          { value: 'Arial', label: 'Arial' },
          { value: 'Georgia', label: 'Georgia' },
          { value: 'Times New Roman', label: 'Times New Roman' },
          { value: 'Courier New', label: 'Courier New' },
          { value: 'Montserrat', label: 'Montserrat' },
          { value: 'Helvetica', label: 'Helvetica' },
          { value: 'serif', label: 'Serif' },
        ],
        fontWeight: [
          { value: '100', label: 'Thin' },
          { value: '200', label: 'Extra Light' },
          { value: '300', label: 'Light' },
          { value: '400', label: 'Normal' },
          { value: '500', label: 'Medium' },
          { value: '600', label: 'Semi Bold' },
          { value: '700', label: 'Bold' },
          { value: '800', label: 'Extra Bold' },
          { value: '900', label: 'Black' },
        ],
        justify: [
          { value: 'flex-start', label: 'Start' },
          { value: 'flex-end', label: 'End' },
          { value: 'center', label: 'Center' },
          { value: 'space-between', label: 'Space Between' },
          { value: 'space-around', label: 'Space Around' },
          { value: 'space-evenly', label: 'Space Evenly' },
        ],
        fit: [
          { value: 'fill', label: 'Fill' },
          { value: 'contain', label: 'Contain' },
          { value: 'cover', label: 'Cover' },
          { value: 'none', label: 'None' },
          { value: 'scale-down', label: 'Scale Down' },
        ],
        radius: [
          { value: '50%', label: 'Round' },
          { value: '0%', label: 'Square' }
        ],

      },
      filters: {
        limit: {
          value: -1,
          items: [
            { value: -1, label: 'All' },
            { value: 1, label: '1' },
            { value: 5, label: '5' },
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' },
            { value: 100, label: '100' }
          ],
        },
        schools: {
          value: null,
          items: [],
          loading: true
        },
        ensembles: {
          value: null,
          items: [],
          loading: true
        },
        instruments: {
          value: null,
          items: [],
          loading: true
        },
        students: {
          value: null,
          items: [],
          loading: true
        },
        teachers: {
          value: null,
          items: [],
          loading: true
        }
      },
      sorting: {
        by: ['name.legal'],
        desc: false,
        drag: false,
        options: [
          { label: 'Legal Name', value: 'name.legal'},
          { label: 'Popular Name', value: 'name.popular'},
        ]
      },
      paging: {
        total: 0,
        page: 1,
        size: 25,
        sizes: [
          { value: -1, label: 'All' },
          { value: 1, label: '1' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 25, label: '25' },
          { value: 50, label: '50' },
          { value: 100, label: '100' }
        ]
      },
      selections: {
        items: [],
        loading: true,
      },
      schools: {
        items: [],
        loading: true
      },
      editor: {
        items: []
      }
    };
  },
  computed: {
    computedSchools() {
      //if selections items is empty return an empty array
      if (!this.schools.items.length) {
        return []
      }

      const fuse = new Fuse(this.schools.items, {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: [
          'application.student.school.id',
          'ensemble.id',
          'application.instrument.id',
          'application.student.id',
          'application.teacher.id'
        ]
      })
      const query = {$and: []}
      if (this.filters.schools.value) {
        query.$and.push({'application.student.school.id': this.filters.schools.value})
      }
      if (this.filters.ensembles.value) {
        query.$and.push({'ensemble.id': this.filters.ensembles.value})
      }
      if (this.filters.instruments.value) {
        query.$and.push({'application.instrument.id': this.filters.instruments.value})
      }
      if (this.filters.students.value) {
        query.$and.push({'application.student.id': this.filters.students.value})
      }
      if (this.filters.teachers.value) {
        query.$and.push({'application.teacher.id': this.filters.teachers.value})
      }

      let {items} = this.schools
      if (query.$and.length) {
        items = fuse.search(query).map(({item}) => item)
      }

      items = this.sortItems(items)
      if(this.paging.size >= 1) {
        this.paging.total = (items.length / 30) // eslint-disable-line vue/no-side-effects-in-computed-properties
        const chunks = _.chunk(items, this.paging.size * 30)
        return chunks[this.paging.page - 1] || []
      }
      return items
      //return items.slice(0, this.filters.limit.value === -1 ? items.length : this.filters.limit.value)
    },
    pagination() {
      let from = 0
      if(this.paging.total > 0) {
        from = (this.paging.size * (30 * (this.paging.page - 1)) + 1)
      }

      let to = (this.paging.size * this.paging.page) * 30
      if(this.paging.size <= 0 || to >= this.selections.items.length) {
        to = this.selections.items.length
      }

      return {
        from: from,
        to: to,
        of: this.selections.items.length,
      }
    },
  },
  async mounted() {
    this.updateContentScaleWidth(1366) // print.mixin property used to determine if the content has been scaled
    const settings = await this.getSettings(this.key)
    if(settings) {
      this.settings = this.deepMerge(this.settings, settings);
      this.initialSettings = _.cloneDeep(this.settings)
    }
    await this.listSchools()
    this.$refs.layout.state.loading = false
  },
  methods: {
    refresh() {
      this.selections.loading = true
      this.listSchools()
    },
    async listSelections() {
      /* eslint-disable no-await-in-loop */
      const items = []
      let nextToken = null;
      do {
        try {
          const input = {
            filter: {
              accepted: { eq: true },
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            },
            limit: 500,
            nextToken: nextToken
          }
          const response = await API.graphql(graphqlOperation(listSelectionsForSchools, input));
          items.push(...response.data.listSelections.items)
          nextToken = response.data.listSelections.nextToken;
        }
        catch (error) {
          console.error(error);
          break;
        }
      }
      while (nextToken);

      const schoolIdsWithAcceptedSelections = []
      items.forEach(item => {
        if (!schoolIdsWithAcceptedSelections.includes(item.application.student.schoolID)) {
          schoolIdsWithAcceptedSelections.push(item.application.student.schoolID)
        }
      })
      return schoolIdsWithAcceptedSelections
    },
    async listSchools() {
      /* eslint-disable no-await-in-loop */
      const items = []
      let nextToken = null;
      do {
        try {
          const input = { limit: 500, nextToken: nextToken }

          const response = await API.graphql(graphqlOperation(listSchools, input));
          items.push(...response.data.listSchools.items)
          nextToken = response.data.listSchools.nextToken;
        }
        catch (error) {
          console.error(error);
          break;
        }
      }
      while (nextToken);


      const schoolIdsWithAcceptedSelections = await this.listSelections()
      this.schools.items = items.filter(school => schoolIdsWithAcceptedSelections.includes(school.id)).sort((a, b) => a.name.legal.localeCompare(b.name.legal));
      this.schools.loading = false
    },
    sortItems(items) {
      const { by, desc } = this.sorting;
      const order = desc ? 'desc' : 'asc';
      const orders = []; // Array to hold the order (asc/desc) for each criteria

      for (let i = 0; i < by.length; i++) {
        orders.push(order);
      }

      return _.orderBy(items, by, orders);
    },

  }
}
</script>

<style>

/* Scaling for smaller screens */
@media (max-width: 1366px) {
    .mailing-label-container {
        transform: scale(95.5%);
        transform-origin: left top;
        -webkit-transform-origin: left top;
    }
    .vertical-menu-modern.menu-collapsed .mailing-label-container {
        transform: none;
        transform-origin: initial;
        -webkit-transform-origin: initial;
    }
}
@media (max-width: 1280px) {
    .mailing-label-container {
        transform: scale(87.5%);
        transform-origin: left top;
        -webkit-transform-origin: left top;
    }
    .vertical-menu-modern.menu-collapsed .mailing-label-container {
        transform: none;
        transform-origin: initial;
        -webkit-transform-origin: initial;
    }
}

@media print {
    .mailing-label-container {
        transform: none!important;
        transform-origin: initial!important;
        -webkit-transform-origin: initial!important;
    }
}

/* Hide everything outside of the container when printing */
@media print {
  @page {
    size: letter portrait!important;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    min-width: unset !important;
  }

  .content-header {
    display: none;
  }

  body *:not(.mailing-label-container, .mailing-label-container *) {
    visibility: hidden;
    padding: 0!important;
    margin: 0!important;
    width: unset!important;
    min-width: unset!important;
    max-width: unset!important;
    /*border: 0;*/
  }
  .mailing-label-container, .mailing-label-container * {
    visibility: visible!important;
  /*  border: 0!important;
    box-shadow: unset;*/
  }

  .mailing-label-container {
    margin: 0!important;
  }
}

.mailing-label-container {
  background: #fff;
  width: 8.5in;
  height: 11in;
  display: grid;
  grid-template-columns: repeat(3, 2.625in);
  grid-template-rows: repeat(10, 1in);
  grid-column-gap: 0.125in;
  padding: 0.5in 0.19in;

  margin: 0;
  box-sizing: border-box;
  /*
   Our .mailing-label has a 1px border. Because we have 3 columns, we need to subtract 3px per the gap, which will total 6px
   where bracket is a border and g is a gap: [x]g[x]g[x] = 2 * 3px = 6px = 2px per column
  */
  /*grid-column-gap: calc(0.15625in - 3px);
  padding: 0.5in 0.19in;*/
  /*grid-column-gap: 0.15625in;
  padding: 0.5in 0.16625in;*/
}

.mailing-label-container:not(:first-child) {
  margin-top: 2rem
}

.overlay-wrapper {
  width: 8.5in;
}

.sorting-btn-border {
  border: 1px solid #d8d6de;
}

</style>
